<template>
  <div id="page-login" :style="`background-image: url(${require('@/assets/images/pages/login/login-bk.jpeg')})`"
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center bg-cover">
    <div class="m-4 md:w-2/5 sm:m-0 sm:w-2/3 vx-col w-3/4 lg:w-1/3 ">
      <v-card>
        <v-card-text>
          <form @submit.prevent="login">

            <div slot="no-body" class="full-page-bg-color">

              <div class="vx-row no-gutter justify-center items-center">

                <div class="vx-col w-full d-theme-dark-bg p-5">
                  <div class="vx-card__title mb-4">
                    <div class="mb-10">
                      <div v-if="logofile_admin_url">
                        <img :src="logofile_admin_url" style=" object-fit: contain" width="191" height="47px"
                          alt="CIT Grup">
                      </div>
                      <div v-else>
                        <img :src="require('@/assets/images/logo/logo.png')" style=" object-fit: contain" width="191"
                          height="47px" alt="CIT Grup">
                      </div>
                    </div>

                    <h4 class="mb-4">Autentificare panou de administrare</h4>
                  </div>

                  <div>
                    <v-text-field v-model="loginData.email" class="w-full mt-6" dense hide-details
                      label-placeholder="E-mail" name="email" outlined placeholder="E-mail" type="email" />
                    <error-messages :errors="errors.email" />

                    <v-text-field ref="password" v-model="loginData.password" class="w-full mt-6" dense hide-details
                      :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show = !show" label-placeholder="Parola" name="password" outlined
                      placeholder="Parola" />

                    <error-messages :errors="errors.password" />

                    <v-btn :disabled="loading" :loading="loading" class="mt-6 primary-button" color="primary"
                      @click="login">
                      Autentificare
                    </v-btn>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import axios from '@/axios'
let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
export default {
  components: { ErrorMessages },
  data () {
    return {
      show: false,
      loading: false,
      logofile_admin_url: '',
      loginData: {
        email: '',
        password: ''
      },
      errors: []
    }
  },
  methods: {
    login () {
      this.loading = true
      this.$store.dispatch('auth/login', this.loginData)

        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else if (response.status === 401) {
            this.errors = {
              password: ['Emailul sau parola nu sunt valide. Va rugam verificati']
            }
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Emailul sau parola nu sunt valide. Va rugam verificati',
              'color': 'danger'
            })

          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLogoUrlsForAdmin () {
      if (window.location.hostname != "admin.citgrup.ro") {
      axios.get(`${baseURL  }/franchisees/getLogoUrlsForAdmin`)
        .then(response => {
          if (response.data) {
            this.logofile_admin_url = response.data.logofile_admin_url
            const fevicon_admin_url = response.data.fevicon_admin_url
            let favicon = document.querySelector('link[rel="icon"]')
            if (!favicon) {
              favicon = document.createElement('link')
              favicon.setAttribute('rel', 'icon')
              favicon.setAttribute('type', 'image/png')
              document.head.appendChild(favicon)
            }
            fevicon_admin_url && favicon.setAttribute('href', fevicon_admin_url)
          }
        })
      }
    },
    getFranchiseeInfoByDomain  () {
      if (window.location.hostname != "admin.citgrup.ro") {
      axios
        .get(baseURL + `/franchisees/getFranchiseeInfoByDomain`)
        .then(response => {
          console.log(response)
          if (response && response.data) {
            console.log(response.data)
            const title = response.data && response.data.name && response.data.name ? `${response.data.name} Admin Panel` : 'Citgrup Admin Panel';
            console.log(title)
            document.title = title;
          }
        })
    }
    },
    getCustomBrandingCssbyDomainForAdmin () {
      if (window.location.hostname != "admin.citgrup.ro") {
      axios
        .get(baseURL + '/franchisees/getCustomBrandingCssbyDomainForAdmin')
        .then(response => {
          if (response.data) {
            var styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(response.data));
            document.getElementsByTagName('head')[0].appendChild(styleElement);
          }
        })
    }
    }
  },
  created () {
    this.getLogoUrlsForAdmin();
    this.getFranchiseeInfoByDomain();
    this.getCustomBrandingCssbyDomainForAdmin()

  }
}
</script>
